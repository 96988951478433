import CryptoJS from 'crypto-js';
import moment from 'moment';
import envKeys from '../../environment';
import { ERROR_RESPONSE_RESULT } from '@api/index';

const { userInfoKey, cryptoKey: key, cryptoIv: iv } = envKeys;

// 2시간으로 설정된 accss_token이 유효한지 확인
export const checkExpire = () => {
    let auth = false;
    if (localStorage.getItem(userInfoKey)) {
        const { oAuthInfo } = getLsUserInfo();
        // 유저 정보와 유저토큰의 기간 만료시간이 있을시에 실행
        // expire_at이 unix timestamp의 seconds로 저장 되어있기 때문에 1000을 곱하여 milliseconds로 전환
        if (oAuthInfo && oAuthInfo.expire_at) {
            const expire = Number(oAuthInfo.expire_at);
            if (expire && moment().isBefore(moment(expire * 1000))) {
                auth = true;
            }
        }
    }
    return auth;
};

// Refresh token은 14일이 default로 설정돼서 오는데 리프레쉬 토큰을 이용해서 유저가 새로우 토큰을 받기전에 리프레쉬 토큰 자체가 유효한지 확인
export const checkRefreshExpire = () => {
    let auth = false;
    const { oAuthInfo } = getLsUserInfo();
    if (oAuthInfo && oAuthInfo.refresh_expire_at) {
        const expire = Number(oAuthInfo.expire_at);
        if (expire && moment().isBefore(moment(expire * 1000))) {
            auth = true;
        }
    }

    return auth;
};

// LocalStorage내의 유저 인포 불러오기
export const getLsUserInfo = () => {
    const userInfo = localStorage.getItem(userInfoKey);
    return userInfo ? decodeInfo(userInfo) : {};
};
// export const getLsUserInfo = () => {
//     const userInfo = JSON.parse(localStorage.getItem(userInfoKey));
//     return userInfo || {};
// };

// 유저 인포 LocalStorage 내에 저장하기
export const setLsUserInfo = info => {
    localStorage.setItem(userInfoKey, encodeInfo(info));
};

export const checkResponseErr = res => {
    const { data } = res;
    return !!(data === null || data === undefined || data.result === ERROR_RESPONSE_RESULT || data.errorCode);
};

// LocalStorage내의 유저가 설정해둔 대쉬보드 홈 불러오기
export const getHome = () => {
    const { userInfo } = getLsUserInfo();
    return userInfo && userInfo.home ? userInfo.home : null;
};

// LocalStorage내의 유저가 속한 회사 정보 불러오기
export const getUuid = () => {
    const { userInfo } = getLsUserInfo();
    // return userInfo && userInfo.uuid ? userInfo.uuid : null;
    if (userInfo && userInfo.companyInfo) {
        return userInfo.companyInfo.uuid;
    }
    return null;
};

export const idxGenerator = function* () {
    let i = 0;
    while (true) {
        yield ++i;
    }
};

// API가 없는 상황에 직접 데이터를 만들어서 하드코딩으로 만들게 된다면 연동할때 처리해야할 상황들이 너무 많아진다
// 그런상황일때 사용 가능하다
export const generateFakeData = data => () => {
    return new Promise(function (resolve, reject) {
        resolve({ data });
    });
};

// useAsync 고도화에 따른 generateFakeData 의 리턴 타입 수정
export const advancedGenerateFakeData = data => () => {
    return {
        fetch: generateFakeData(data),
        abort: () => {},
    };
};

// API가 없는 상황에 직접 데이터를 만들어서 하드코딩으로 만들게 된다면 연동할때 처리해야할 상황들이 너무 많아진다
// 그런상황일때 사용 가능하다
export const generateDynamicFakeData = generateData => () => {
    return new Promise(function (resolve, reject) {
        resolve({ data: generateData() });
    });
};

// useAsync 고도화에 따른 generateDynamicFakeData 의 리턴 타입 수정
export const advancedGenerateDynamicFakeData = generateData => () => {
    return {
        fetch: generateDynamicFakeData(generateData),
    };
};

// 암호화
export const encodeInfo = info => {
    const cryptoKey = CryptoJS.enc.Utf8.parse(key);
    const ivUtf = CryptoJS.enc.Utf8.parse(iv);
    const encrypted = CryptoJS.AES.encrypt(JSON.stringify(info), cryptoKey, {
        iv: ivUtf,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
    }).toString();
    return encrypted;
};

// 복호화
// @param encodedInfo 암호화된 정보
// @param toString string타입으로 리턴 여부
export const decodeInfo = (encodedInfo, toString) => {
    const cryptoKey = CryptoJS.enc.Utf8.parse(key);
    const ciphertext = CryptoJS.enc.Base64.parse(encodedInfo);
    const ivUtf = CryptoJS.enc.Utf8.parse(iv);

    const decrypt = CryptoJS.AES.decrypt({ ciphertext: ciphertext }, cryptoKey, {
        iv: ivUtf,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
    });
    let decInfo = {};
    try {
        const decInfo = decrypt.toString(CryptoJS.enc.Utf8);
        if (toString || !decInfo) {
            return decInfo;
        }
        return JSON.parse(decInfo);
    } catch (error) {
        console.error(error);
    }
    return decInfo;
};

const dateFormat = {
    START_YEAR: 'YYYY-MM-DD HH:mm:ss',
    START_YEAR_2: 'YYYY-MM-DD h:mm:ss a',
    START_MONTH: 'MMMM Do, h:mm:ss a',
    START_MONTH_2: 'MM-DD HH:mm:ss',
    MD: 'MMMM Do',
    ONLY_TIME: 'h:mm:ss a',
};

export const dateToFormat = (date, format = 'START_YEAR') => {
    return moment(date).format(dateFormat[format] || format);
};

export const defaultActionCreator = (type, param) => ({ type, payload: param });

export const secToHms = sec => {
    const tempMin = sec / 60;
    let h = Math.floor(tempMin / 60);
    if (h !== 0 && !h) {
        h = null;
    }
    let m = Math.floor(tempMin % 60);
    if (m !== 0 && !m) {
        m = null;
    }
    return {
        h: h,
        m: m,
        s: sec % 60,
    };
};

export const getDashboardNum = async function () {
    return await fetch('config/app.json')
        .then(response => {
            return response.json();
        })
        .catch(error => {
            console.log({ error });
            return {};
        });
};

export const getStaticSingleGridFullHeight = (withFilter, additionalHeight) => {
    // - header - footer - padding of content - cardHeader - padding of card body - margin of card
    // + (화면 꽉 채우기 위한 조정값 : 컨텐츠 바텀 패딩(2.5rem)이 카드 마진 바텀(1rem) + 푸터(30px)와 겹친다고 생각하고 총 1.5rem 추가)
    return `calc(100vh - 60px - 30px - 3.5rem - 56px - 2rem - 1rem + 1.5rem ${withFilter ? '- 56px' : ''} ${
        additionalHeight ?? ''
    })`;
};

// Generate uuid version 4
export const initUUIDv4 = () => {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16),
    );
};

export const isObject = object => {
    return object != null && typeof object === 'object';
};

export const isDeepEqual = (object1, object2) => {
    const objKeys1 = Object.keys(object1);
    const objKeys2 = Object.keys(object2);

    if (objKeys1.length !== objKeys2.length) {
        return false;
    }

    for (const key of objKeys1) {
        const val1 = object1[key];
        const val2 = object2[key];
        const areObjects = isObject(val1) && isObject(val2);
        if ((areObjects && !isDeepEqual(val1, val2)) || (!areObjects && val1 !== val2)) {
            return false;
        }
    }

    return true;
};

export const copyToClipboard = (textToCopy, resolve) => {
    if (window.navigator.clipboard) {
        window.navigator.clipboard.writeText(textToCopy).then(resolve);
    } else {
        // For http
        const textArea = document.createElement('textarea');
        textArea.value = textToCopy;
        document.body.appendChild(textArea);
        textArea.select();
        textArea.setSelectionRange(0, 99999);
        try {
            document.execCommand('copy');
        } catch (err) {
            console.error(err);
        }
        textArea.setSelectionRange(0, 0);
        document.body.removeChild(textArea);
        resolve();
    }
};

export const mergeObjects = (obj1, obj2) => {
    const merged = { ...obj1 };

    for (const key in obj2) {
        if (obj2.hasOwnProperty(key)) {
            if (obj2[key] !== null && typeof obj2[key] === 'object' && !Array.isArray(obj2[key])) {
                // 중첩 객체인 경우 재귀적으로 병합
                merged[key] = mergeObjects(obj1[key] || {}, obj2[key]);
            } else {
                // 중첩 객체가 아닌 경우 필드 덮어씌우기
                merged[key] = obj2[key];
            }
        }
    }
    return merged;
};

// null, undefined,빈문자열, 0 , -0, NaN, 빈 객체, 빈 배열 체크
export const isEmpty = param => {
    let checkStr = param;
    if (typeof checkStr === 'string') {
        checkStr = checkStr.trim();
    }
    if (typeof checkStr === 'object') {
        if (checkStr) {
            return Object.keys(param).length === 0;
        }
    }
    return !Boolean(checkStr);
};

// is null or undefined
export const isNil = value => {
    return value == null;
};

export const getRandom = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
};

export const getSample = (collection = []) => {
    return collection.length ? collection[getRandom(0, collection.length - 1)] : null;
};

export const sleep = ms => {
    const wakeUpTime = Date.now() + ms;
    while (Date.now() < wakeUpTime) {}
};

// 원시타입 요소 비교
export const arraysAreEqual = (arr1 = [], arr2 = []) => {
    // 길이가 같지 않으면 바로 false 반환
    if (arr1.length !== arr2.length) {
        return false;
    }
    // arr1의 모든 요소가 arr2에 포함되어 있는지 확인
    const allInArr2 = arr1.every(item => arr2.includes(item));
    // arr2의 모든 요소가 arr1에 포함되어 있는지 확인
    const allInArr1 = arr2.every(item => arr1.includes(item));
    // 두 조건이 모두 true면 배열은 같은 요소를 가짐
    return allInArr2 && allInArr1;
};

export const base64ToBlob = async (base64, type = 'application/octet-stream') =>
    fetch(`data:${type};base64,${base64}`).then(res => res.blob());

export const roundNumber = (number, decimalPlace) =>
    typeof number === 'number' ? Number(number.toFixed(decimalPlace)) : number;
