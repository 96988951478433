import React, { Dispatch, SetStateAction, useRef } from 'react';
import { DEFAULT_CHART_OPTIONS, LINE_CHART_COLOR, WHITE_COLOR } from '../../constants';
import { mergeObjects } from '@util/common/util';
import getVitalSingsTooltip from '../../../../util/chart/plugins/vitalSingsTooltip';
import { LineChart, LoadingBlock } from '@components';
import { useAppSelector, useTranslation } from '@hooks';
import { ChartData, ChartLoading } from './VitalSignsView';
import { Chart } from 'react-chartjs-2';
import PartialBackgroundColor from '@components/Charts/Plugins/PartialBackgroundColor';
import NoDataBlock from '../../../../Components/NoDataBlock';
import styled from 'styled-components';

type VitalSignsChartProps = {
    chartData: ChartData;
    isLoading?: boolean;
    setLoading?: Dispatch<SetStateAction<ChartLoading>>;
};

const VitalSignsChart = ({ chartData, isLoading, setLoading }: VitalSignsChartProps) => {
    const t = useTranslation('BiometricInformationMonitoring');
    const colorScheme = useAppSelector(state => state.ThemeOptions.colorScheme);
    const isDataChanged = useRef<boolean>(false);

    return (
        <ChartWrapper>
            <h5 className={'pnt-txt txt-bold s-6 mb-1'}>{`${chartData.name} ${
                chartData?.unit ? '(' + chartData?.unit + ')' : ''
            }`}</h5>
            <div className={'line-chart-area'}>
                <LoadingBlock blocking={isLoading}>
                    {!chartData.data.length ? (
                        <NoDataBlock className={'no-data'} />
                    ) : (
                        <LineChart
                            data={{
                                datasets: [
                                    {
                                        data: chartData.data,
                                        label: chartData.name,
                                        forcedPointBackgroundColor: WHITE_COLOR,
                                        forcedBorderColor: LINE_CHART_COLOR,
                                        forcedPointBorderColor: LINE_CHART_COLOR,
                                        forcedBackgroundColor: LINE_CHART_COLOR,
                                    },
                                ],
                                labels: chartData.labels,
                            }}
                            options={mergeObjects(DEFAULT_CHART_OPTIONS, {
                                plugins: {
                                    ...getVitalSingsTooltip(colorScheme, t),
                                    loading: {
                                        afterDatasetsUpdate: (chart: Chart) => {
                                            if (chart.data.datasets[0]?.label) {
                                                if (typeof setLoading === 'function') {
                                                    isDataChanged.current = true;
                                                }
                                            }
                                        },
                                        afterRender: (chart: Chart) => {
                                            if (isDataChanged.current && typeof setLoading === 'function') {
                                                setLoading((prev: ChartLoading) => {
                                                    return { ...prev, [chartData.sensorType]: false };
                                                });
                                            }
                                            isDataChanged.current = false;
                                        },
                                    },
                                },
                                scales: {
                                    y:
                                        chartData.min === 0 && chartData.max === 0
                                            ? { min: 0, max: 1 }
                                            : chartData.min === chartData.max
                                            ? {
                                                  min: chartData.min - chartData.min * 0.2,
                                                  max: chartData.max + chartData.max * 0.2,
                                              }
                                            : {
                                                  min: chartData.min - (chartData.max - chartData.min) * 0.2,
                                                  max: chartData.max + (chartData.max - chartData.min) * 0.2,
                                              },
                                },
                            })}
                            plugins={[PartialBackgroundColor]}
                        />
                    )}
                </LoadingBlock>
            </div>
        </ChartWrapper>
    );
};

const ChartWrapper = styled.div`
    & .line-chart-area {
        height: 300px;
        .no-data {
            border: 1px solid #eceaf2;
        }
    }
`;

export default React.memo(VitalSignsChart);
