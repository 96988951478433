import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { LoadingBlock } from '@components';
import useAsync from '@hooks/useAsync';
import { useBiometricInformationDashboardStateContext } from '../../slice';
import Label from '@components/Label';
import { getBatteryDisplayValue } from '../../../../Components/TableCommon/RemainsBattery/util';
import useTranslation from '@hooks/useTranslation';
import { getIotItemApi } from '@api/common/asset';
import { useAppSelector } from '@hooks';
import styled from 'styled-components';
import { LabelProps } from '@components/type';
import { MappedSensor } from '@api/sh/biometricInformationMonitoring';
import { MODEL_CODE_MEZOO_SMARTPATCH } from '@util/staticData/modelCode';

const DetailsInfo = () => {
    const t = useTranslation('BiometricInformationMonitoring');
    const { selectedPatient } = useBiometricInformationDashboardStateContext();
    const wardMapInfo = useAppSelector(state => state.DepartmentsInfo.wardMapInfo);

    const {
        state: { isLoading, response },
    } = useAsync({
        promise: getIotItemApi,
        fixedParam: { targetNum: selectedPatient?.targetNum },
        immediate: true,
        deps: [selectedPatient?.targetNum],
    });

    return (
        <LoadingBlock blocking={isLoading}>
            <PerfectScrollbar className={'p-3 border border-depth-7 d-flex flex-column gap-4'}>
                <FixedLabel name={t('Patient Name')} value={response?.targetName ?? '-'} />
                <FixedLabel name={t('Patient Number')} value={response?.targetId ?? '-'} />
                <FixedLabel
                    name={t('Ward')}
                    value={response?.properties?.ward ? wardMapInfo[response.properties.ward] : '-'}
                />
                <FixedLabel name={t('Room')} value={response?.properties?.room ?? '-'} />
                <FixedLabel name={t('Bed')} value={response?.properties?.bed ?? '-'} />
                <span className={'pnt-divider horizon-line'} />
                <FixedLabel
                    name={t('Device Name')}
                    value={getSensorInfo(MODEL_CODE_MEZOO_SMARTPATCH, response?.mappedSensorList)?.sensorName}
                />
                <FixedLabel
                    name={t('Battery')}
                    value={getBatteryDisplayValue(
                        getSensorInfo(MODEL_CODE_MEZOO_SMARTPATCH, response?.mappedSensorList)?.recentSensorValues
                            ?.BATTERY ?? 0,
                    )}
                />
            </PerfectScrollbar>
        </LoadingBlock>
    );
};

const getSensorInfo = (modelCode: string, sensorList?: MappedSensor[]): MappedSensor | null | undefined => {
    return sensorList ? sensorList.find(sensor => sensor.modelCode === modelCode) : null;
};

const LabelWrapper = ({ className, ...restProps }: LabelProps & { className?: string }) => {
    return (
        <span className={className}>
            <Label {...restProps} />
        </span>
    );
};

const FixedLabel = styled(LabelWrapper)`
    .label-main {
        flex: 0 1 40%;
    }
`;

export default DetailsInfo;
