import React from 'react';
import biometricInformationMonitoringSlice, {
    BiometricInformationMonitoringDispatchContext,
    BiometricInformationMonitoringStateContext,
    useBiometricInformationMonitoringDispatchContext,
    useBiometricInformationMonitoringStateContext,
    setSelectedPatient,
} from './slice';
import ContextProvider from '@components/ContextProvider';
import { WidgetProps } from '../../staticInfo';
import WidgetCard from '../../Components/WidgetCard';
import { useSettings } from '../../util/useSettings';
import PatientsMonitoring from './Components/PatientsMonitoring';
import Search from './Components/PatientsMonitoring/Search';
import PatientDetails from './Components/PatientsMonitoring/PatientDetails';
import Button from '@components/Button';
import { useTranslation } from '@hooks';

const BiometricInformationMonitoring = ({ widgetInfo, children, ...restProps }: WidgetProps) => {
    return (
        <ContextProvider
            StateContext={BiometricInformationMonitoringStateContext}
            DispatchContext={BiometricInformationMonitoringDispatchContext}
            slice={biometricInformationMonitoringSlice}
        >
            <ContextConsumer widgetInfo={widgetInfo} {...restProps}>
                {children}
            </ContextConsumer>
        </ContextProvider>
    );
};

const ContextConsumer = ({ widgetInfo, children, ...restProps }: WidgetProps) => {
    const t = useTranslation('BiometricInformationMonitoring');
    const settings = useSettings(widgetInfo.config);
    const dispatch = useBiometricInformationMonitoringDispatchContext();
    const { selectedPatient } = useBiometricInformationMonitoringStateContext();

    return (
        <WidgetCard
            widgetInfo={widgetInfo}
            {...restProps}
            headerAction={
                selectedPatient && (
                    <Button className="btn-lightgray" onClick={() => dispatch(setSelectedPatient(null))}>
                        {t('Back', 'Button')}
                    </Button>
                )
            }
            searchFilter={!selectedPatient && <Search />}
        >
            <div className={'w-100 h-100'}>{selectedPatient ? <PatientDetails /> : <PatientsMonitoring />}</div>
            {children}
        </WidgetCard>
    );
};

export default BiometricInformationMonitoring;
