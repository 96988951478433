import React from 'react';
import DetailsInfo from './DetailsInfo';
import VitalSignsView from './VitalSignsView';
import styled from 'styled-components';

const PatientDetails = () => {
    return (
        <DetailViewWrapper>
            <DetailsInfo />
            <VitalSignsView />
        </DetailViewWrapper>
    );
};

const DetailViewWrapper = styled.div`
    height: 100%;
    min-height: 100%;
    display: grid;
    grid-template-columns: 1fr 3fr;
    column-gap: 1rem;
`;

export default PatientDetails;
