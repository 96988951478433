import { useColumns, useTranslation } from '@hooks';
import * as mainCols from '../../../../../MainPages/util/grid/column';
import { UnixTimestamp } from '@util/type/util';
import moment from 'moment';
import {
    BatteryCell,
    HeartbeatValueCell,
    RespirationRateCell,
    TemperatureCell,
} from '../../../../Components/TableCommon/Cells';
import { BookmarkCell, RegisteredDateCell, SensorNameCell } from './Cells';

export const usePatientsTableColumns = () => {
    const t = useTranslation('BiometricInformationMonitoring');

    return useColumns(
        [
            {
                Header: 'Bed',
                accessor: 'bed',
                className: 'text-ellipsis',
                width: 60,
            },
            mainCols.targetName({ Header: 'Patient Name', className: 'text-ellipsis', width: 80 }),
            mainCols.targetId({ Header: 'Patient Number', className: 'text-ellipsis', width: 80 }),
            {
                Header: 'HR',
                headerClassName: 'bg-depth-9',
                className: 'p-0',
                accessor: 'recentSensorValues.HEARTBEAT',
                width: 60,
                Cell: HeartbeatValueCell,
            },
            {
                Header: 'RR',
                headerClassName: 'bg-depth-9',
                className: 'p-0',
                accessor: 'recentSensorValues.RESPIRATION_RATE',
                width: 60,
                Cell: RespirationRateCell,
            },
            {
                Header: 'BT',
                headerClassName: 'bg-depth-9',
                className: 'p-0',
                accessor: 'recentSensorValues.TEMPERATURE',
                width: 60,
                Cell: TemperatureCell,
            },
            mainCols.battery({
                headerClassName: 'justify-content-center',
                className: 'justify-content-center',
                width: 60,
                accessor: 'recentSensorValues.BATTERY',
                Cell: BatteryCell,
            }),
            mainCols.registeredDate({
                Header: 'Recognition Time',
                headerClassName: 'justify-content-center',
                className: 'p-0',
                width: 120,
                accessor: 'lastDate',
                formatTitle: ({ value }: { value: UnixTimestamp }) => {
                    return value ? moment(value * 1000).format('YYYY-MM-DD HH:mm:ss') : '';
                },
                Cell: RegisteredDateCell,
            }),
            mainCols.sensorName({
                Header: 'Device Name',
                headerClassName: 'justify-content-center',
                className: 'flex-center justify-content-center',
                width: 90,
                Cell: SensorNameCell,
            }),
            mainCols.geofenceName({
                Header: 'Location',
                headerClassName: 'justify-content-center',
                accessor: 'fcNames',
                width: 60,
            }),
            mainCols.bookmark({
                className: 'flex-center justify-content-center',
                width: 60,
                accessor: 'isBookmark',
                Cell: BookmarkCell,
            }),
        ],
        t,
    );
};
