import React, { useState } from 'react';
import { Button, ConfirmModal, Modal, Table } from '@components';
import { ModalFooter } from 'reactstrap';
import { useAsync, useColumns, useConfirm, useTranslation } from '@hooks';
import { deleteDeviceMappingApi, getTargetInfoApi } from '@api/sh/deviceManagement';
import { MODEL_CODE_MEZOO_SMARTPATCH } from '@util/staticData/modelCode';
import * as col from './columns';
import DeviceSearchModal from './DeviceSearchModal';

type DeviceType = 'SENSOR' | 'SCANNER' | 'BEACON';

interface Props {
    isOpen: boolean;
    toggleModal: () => void;
    targetNum: number;
    deviceNum?: number;
}

const SENSOR = 'SENSOR';

const DeviceInfoModal = ({ isOpen, toggleModal, targetNum, deviceNum }: Props) => {
    const t = useTranslation('DeviceMappingManagement');
    const [showDeviceSearchModal, setShowDeviceSearchModal] = useState(false);

    const unMappingModalProps = useConfirm({
        initModal: false,
        confirmText: (
            <div>
                <div>{t('Do you want to unmap?')}</div>
                <div>{t('If you unmap, you will no longer be able to monitor the details.')}</div>
            </div>
        ),
        okCallback: () => {
            deleteDeviceMapping({ targetNum, sensorNumList: deviceNum });
        },
    });

    const confirmUnmappingModalProps = useConfirm({
        initModal: false,
        confirmText: (
            <div>
                <div>{t('The mapping is unmapped.')}</div>
                <div>{t('To remap, search for your device and select the device you want to map.')}</div>
            </div>
        ),
        removeCancel: true,
        okCallback: () => {
            // 상세정보 리프레쉬
            getTargetInfo();
        },
    });

    // 대상 정보 상세 조회
    const { promise: getTargetInfo, state: targetInfo } = useAsync({
        promise: getTargetInfoApi,
        fixedParam: {
            modelCodeList: [MODEL_CODE_MEZOO_SMARTPATCH],
            targetNum,
            sensorNum: deviceNum ?? undefined,
        },
        keepState: true,
        immediate: isOpen, // 초기 렌더링 시 API 호출 방지(모달을 열었을 때만 실행시키기 위함)
        deps: [isOpen],
    });

    // 언매핑 API
    const { promise: deleteDeviceMapping } = useAsync({
        promise: deleteDeviceMappingApi,
        resolve: () => {
            confirmUnmappingModalProps.toggleModal();
        },
    });

    const deviceColumns = useColumns(
        [
            col.deviceName(),
            col.actions({
                Cell: () => {
                    return (
                        <Button
                            className="btn-icon-only btn-trans br-5 icon-big"
                            iconName={'link_off'}
                            onClick={() => {
                                unMappingModalProps.toggleModal();
                            }}
                        />
                    );
                },
            }),
        ],
        t,
        [],
    );

    const { wardName, room, bed, targetName, targetDevice } = targetInfo?.response || {
        wardName: null,
        room: null,
        bed: null,
        targetName: null,
        targetDevice: null,
    };

    const targetDeviceList = targetDevice
        ? targetDevice.filter(({ deviceType }: { deviceType: DeviceType }) => deviceType === SENSOR)
        : [];

    return (
        <>
            <Modal
                initModal={isOpen}
                toggleModal={toggleModal}
                removeModalClose={false}
                headerTitle={<div>{t('Biometric monitoring device info')}</div>}
                bodyText={
                    <div className={'h-100'}>
                        <div className={'d-flex flex-column gap-1 mb-3'}>
                            <div className={'pnt-txt txt-dot txt-bold s-6'}>{t('Patient information')}</div>
                            <div className={'d-flex ml-2 pl-1'}>
                                <span className={'w-30'}>{t('Ward/Room/Bed')}</span>
                                <span>
                                    {wardName || '-'} / {room || '-'} / {bed || '-'}
                                </span>
                            </div>
                            <div className={'d-flex ml-2 pl-1'}>
                                <span className={'w-30'}>{t('Patient name')}</span>
                                <span>{targetName || '-'}</span>
                            </div>
                        </div>
                        <div className={'d-flex justify-content-between mb-2'}>
                            <div className={'pnt-txt txt-dot txt-bold s-6'}>{t('Device information')}</div>
                            <Button
                                className={'btn-secondary'}
                                onClick={() => {
                                    setShowDeviceSearchModal(true);
                                }}
                                disabled={!!targetDeviceList.length}
                            >
                                {t('Search device')}
                            </Button>
                        </div>
                        <div style={{ height: '80px' }}>
                            <Table columns={deviceColumns} data={{ rows: targetDeviceList }} paging={false} />
                        </div>
                    </div>
                }
                modalFooter={
                    <ModalFooter className={'d-flex justify-content-end'}>
                        <Button
                            className={'btn-secondary'}
                            onClick={() => {
                                toggleModal();
                            }}
                        >
                            {t('Close', 'Button')}
                        </Button>
                    </ModalFooter>
                }
            />
            <ConfirmModal {...unMappingModalProps} />
            <ConfirmModal {...confirmUnmappingModalProps} />
            <DeviceSearchModal
                isOpen={showDeviceSearchModal}
                targetNum={targetNum}
                toggleModal={() => {
                    setShowDeviceSearchModal(prev => !prev);
                }}
                closeCallback={() => {
                    getTargetInfo();
                }}
            />
        </>
    );
};

export default DeviceInfoModal;
