import { createContext, Dispatch, useContext } from 'react';
import { createSlice } from '@reduxjs/toolkit';
import { ActionType } from '@components/ContextProvider';
import { Nullable, NullableNumber, YN } from '@util/type/util';
import { MappedSensor, MonitoringPatient } from '@api/sh/biometricInformationMonitoring';
import { MODEL_CODE_MEZOO_SMARTPATCH } from '@util/staticData/modelCode';
import { roundNumber } from '@util/common/util';

type MonitoringFilter = {
    wardList?: string[];
    roomList?: string[];
    isMapping?: YN;
    isBookmark?: YN;
};

export type DisplayedMonitoringPatient = Omit<MonitoringPatient, 'mappedSensorList'> & Partial<MappedSensor>;

export const initialState: {
    selectedPatient: Nullable<DisplayedMonitoringPatient>;
    monitoringFilter: MonitoringFilter;
    monitoringList: DisplayedMonitoringPatient[];
} = {
    selectedPatient: null,
    monitoringFilter: {
        wardList: [],
        roomList: [],
        // isMapping: 'Y',
        // 배포시 Y 로 변경 필요
        isMapping: 'Y',
    },
    monitoringList: [],
};

export const BiometricInformationDashboardStateContext = createContext(initialState);
export const BiometricInformationDashboardDispatchContext = createContext<Dispatch<ActionType> | null>(null);

export const useBiometricInformationDashboardStateContext = () => useContext(BiometricInformationDashboardStateContext);
export const useBiometricInformationDashboardDispatchContext = () =>
    useContext(BiometricInformationDashboardDispatchContext)!;

const biometricInformationDashboardSlice = createSlice({
    name: 'sh/biometricInformationDashboard',
    initialState,
    reducers: {
        setSelectedPatient: (state, action) => {
            state.selectedPatient = action.payload;
        },
        setMonitoringFilter: (state, action) => {
            const newFilter = action.payload;
            state.monitoringFilter = { ...state.monitoringFilter, ...newFilter };
        },
        setMonitoringPatients: (state, action) => {
            const { patients } = action.payload;
            state.monitoringList = patients.reduce((acc: DisplayedMonitoringPatient[], patient: MonitoringPatient) => {
                const { mappedSensorList, ...restPatientInfo } = patient;

                const sensorToDisplay = mappedSensorList.find(
                    sensorInfo => sensorInfo.modelCode === MODEL_CODE_MEZOO_SMARTPATCH,
                );
                processSensingData(sensorToDisplay);
                const newPatient: DisplayedMonitoringPatient = {
                    ...restPatientInfo,
                    ...sensorToDisplay,
                };
                acc.push(newPatient);
                return acc;
            }, []);
        },
        updateSensingData: (state, action) => {
            const { targetNum, ...sensingData } = action.payload;
            state.monitoringList = state.monitoringList.map(patient => {
                if (patient.targetNum === targetNum) {
                    processSensingData(sensingData, patient);
                    return { ...patient, ...sensingData };
                }
                return patient;
            });
        },
    },
});

const processSensingData = (
    sensingData?: Pick<DisplayedMonitoringPatient, 'recentSensorValues' | 'recentSensorStates'>,
    prevPatient?: DisplayedMonitoringPatient,
) => {
    if (sensingData?.recentSensorValues && sensingData?.recentSensorStates) {
        getKeepSensorType(sensingData.recentSensorValues).forEach(sensorType => {
            sensingData.recentSensorValues![sensorType] = prevPatient?.recentSensorValues?.[sensorType] ?? null;
            sensingData.recentSensorStates![sensorType] = prevPatient?.recentSensorStates?.[sensorType] ?? null;
        });
        roundSensingValues(sensingData.recentSensorValues);
    }
};

type SensorType = keyof MappedSensor['recentSensorValues'];
const SENSOR_TYPE_ZERO_TO_KEEP: SensorType[] = ['HEARTBEAT', 'RESPIRATION_RATE'];
const getKeepSensorType = (sensorValues?: Nullable<{ [sensorType in SensorType]: NullableNumber }>) => {
    return SENSOR_TYPE_ZERO_TO_KEEP.filter(sensorType => sensorValues?.[sensorType] === 0);
};

const roundSensingValue = (
    sensorType: SensorType,
    decimalPlace: number,
    sensorValues?: Nullable<{ [sensorType in SensorType]: NullableNumber }>,
) => {
    if (sensorValues && typeof sensorValues[sensorType] === 'number') {
        sensorValues[sensorType] = roundNumber(sensorValues[sensorType], decimalPlace);
    }
};

const roundSensingValues = (sensorValues?: Nullable<{ [sensorType in SensorType]: NullableNumber }>) => {
    roundSensingValue('TEMPERATURE', 1, sensorValues);
    roundSensingValue('RESPIRATION_RATE', 0, sensorValues);
    roundSensingValue('HEARTBEAT', 0, sensorValues);
};

export const {
    setSelectedPatient,
    setMonitoringFilter,
    setMonitoringPatients,
    updateSensingData,
} = biometricInformationDashboardSlice.actions;

export default biometricInformationDashboardSlice;
