import React, { useState } from 'react';
import { Button, ConfirmModal, Table } from '@components';
import { useAppSelector, useAsync, useColumns, useConfirm, useTranslation } from '@hooks';
import * as column from '../../columns';
import DeviceInfo from './DeviceInfo';
import {
    setTargetInfo,
    TargetInfo,
    useDeviceMappingManagementDispatchContext,
    useDeviceMappingManagementStateContext,
} from '../../slice';
import { deleteDeviceMappingApi, getTargetDeviceListApi } from '@api/sh/deviceManagement';
import DeviceInfoModal from '../Modal/DeviceInfoModal';
import { MODEL_CODE_MEZOO_SMARTPATCH } from '@util/staticData/modelCode';

const PatientsDeviceList = () => {
    const t = useTranslation('DeviceMappingManagement');
    const dispatch = useDeviceMappingManagementDispatchContext();
    const {
        targetInfo: { targetNum, sensorNum },
        monitoringFilter: { wardList: monitoringWardList, roomList },
    } = useDeviceMappingManagementStateContext();
    const [showDeviceInfoModal, setShowDeviceInfoModal] = useState(false);

    const wardList = useAppSelector(state =>
        Object.entries(state.DepartmentsInfo.wardMapInfo).map(([wardNum, wardName]) => ({ wardNum, wardName })),
    );

    const handleInfoToggle = (targetData: TargetInfo) => {
        dispatch(setTargetInfo(targetData));
        setShowDeviceInfoModal(true);
    };

    const handleRemoveClick = (targetData: TargetInfo) => () => {
        dispatch(setTargetInfo(targetData));
        unMappingModalProps.toggleModal();
    };

    const { promise: getTargetDeviceList, state: targetDeviceList } = useAsync({
        promise: getTargetDeviceListApi,
        fixedParam: {
            categoryCodes: 'PATIENT',
            modelCodeList: [MODEL_CODE_MEZOO_SMARTPATCH],
            targetPropertiesSearch: {
                propertySearchs:
                    monitoringWardList.length !== 0
                        ? monitoringWardList.map((ward: string) => ({
                              propertyId: 'ward',
                              values: ward,
                          }))
                        : wardList.map(({ wardNum }) => ({
                              propertyId: 'ward',
                              values: wardNum,
                          })),
            },
            roomList,
            page: 1,
            pageSize: 15,
        },
        immediate: true,
        keepState: true,
        deps: [monitoringWardList.length, roomList.length],
    });

    const { promise: deleteDeviceMapping } = useAsync({
        promise: deleteDeviceMappingApi,
        resolve: () => {
            getTargetDeviceList();
        },
    });

    const unMappingModalProps = useConfirm({
        initModal: false,
        confirmText: (
            <div>
                <div>{t('Do you want to unmap?')}</div>
                <div>{t('If you unmap, you will no longer be able to monitor the details.')}</div>
            </div>
        ),
        okCallback: () => {
            deleteDeviceMapping({ targetNum, sensorNumList: sensorNum });
        },
    });

    const columns = useColumns(
        [
            column.warddept(),
            column.room(),
            column.targetName({
                width: 60,
                Cell: ({ value }: { value: string }) => {
                    return (
                        <div className={'text-ellipsis'} title={value}>
                            {value}
                        </div>
                    );
                },
            }),
            column.mappingDevice({
                Cell: ({ row: { original } }: { row: { original: any } }) => {
                    const {
                        targetDevice: { sensorList },
                        targetNum,
                    } = original;

                    const targetData = {
                        targetNum,
                        sensorNum: sensorList ? sensorList[0].deviceNum : null,
                    };

                    return sensorList ? (
                        <DeviceInfo
                            deviceName={sensorList[0].sensorName}
                            onRemoveClick={handleRemoveClick(targetData)}
                        />
                    ) : null;
                },
            }),
            column.actions({
                Cell: ({ row: { original } }: { row: { original: any } }) => {
                    const {
                        targetNum,
                        targetDevice: { sensorList },
                    } = original;

                    const targetData = {
                        targetNum,
                        sensorNum: sensorList ? sensorList[0].deviceNum : null,
                    };

                    return (
                        <Button
                            className="btn-icon-only btn-trans br-5 icon-big"
                            iconName={'add_circle_outline'}
                            onClick={() => handleInfoToggle(targetData)}
                        />
                    );
                },
            }),
        ],
        t,
        [],
    );

    const response = targetDeviceList?.response || { rows: [], page: 1 };

    return (
        <>
            <div className={'h-90'}>
                <Table
                    columns={columns}
                    data={{ ...response, pageSize: 15 }}
                    onPageChange={page => {
                        // 페이징
                        getTargetDeviceList({ page });
                    }}
                    dynamicRowHeight
                />
            </div>
            <ConfirmModal {...unMappingModalProps} />
            <DeviceInfoModal
                isOpen={showDeviceInfoModal}
                toggleModal={() => {
                    setShowDeviceInfoModal(prev => !prev);
                }}
                closeCallback={() => {
                    getTargetDeviceList();
                }}
            />
        </>
    );
};

export default PatientsDeviceList;
