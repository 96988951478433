import React from 'react';
import { Nullable, NullableNumber } from '@util/type/util';
import { isNil } from '@util/common/util';
import RemainsBattery from './RemainsBattery';
import SensorValueCell, { SensorStates } from './SensorValue';

export const BatteryCell = ({ value }: { value: NullableNumber }) => {
    return isNil(value) ? null : <RemainsBattery value={Math.round(value!)} />;
};

type AnySensorData<T extends string, S extends string = 'recentSensorStates'> = {
    [recentSensorStates in S]: Nullable<SensorStates<T>>;
};

export const HeartbeatValueCell = ({
    value,
    row: { original },
}: {
    value: NullableNumber;
    row: { original: AnySensorData<'HEARTBEAT'> };
}) => {
    return <SensorValueCell value={value} states={original.recentSensorStates} sensorType={'HEARTBEAT'} />;
};

export const RespirationRateCell = ({
    value,
    row: { original },
}: {
    value: NullableNumber;
    row: { original: AnySensorData<'RESPIRATION_RATE'> };
}) => {
    return <SensorValueCell value={value} states={original.recentSensorStates} sensorType={'RESPIRATION_RATE'} />;
};

export const TemperatureCell = ({
    value,
    row: { original },
}: {
    value: NullableNumber;
    row: { original: AnySensorData<'TEMPERATURE'> };
}) => {
    return <SensorValueCell value={value} states={original.recentSensorStates} sensorType={'TEMPERATURE'} />;
};
