import React, { useState } from 'react';

import { Table } from '@components';
import { useAppSelector, useAsync, useColumns, useTranslation } from '@hooks';
import { CATEGORY_CODE, SENSING_TYPE_TO_LABEL } from '@util/mappInfo';
import * as mainCols from '../../../MainPages/util/grid/column';

import WidgetCard from '../../Components/WidgetCard';
import Search, { DateRange, INIT_SENSOR_STATE } from './Components/Search';
import moment from 'moment';
import { WidgetProps } from '../../staticInfo';
import { UnixTimestamp } from '@util/type/util';
import { getEndOfDate, getStartOfDate } from '@util/date';
import { SCREEN_MODE_EDIT, SCREEN_MODE_PREVIEW } from '@reducer/ScreenInfo';
import ExcelExport from './Components/ExcelExport';
import { EmergencyAlertLog, getEmergencyAlertLogApi } from '@api/sh/biometricInformationMonitoring';

const initWardList = (groupNums?: number[]) => {
    return (groupNums || []).map(num => String(num));
};

const PatientAnomalyHistory = ({ children, widgetInfo, ...restProps }: WidgetProps) => {
    const t = useTranslation('BiometricInformationMonitoring');
    const mode = useAppSelector(state => state.ScreenInfo.mode);
    const userInfo: { groupNums?: number[] } = useAppSelector(state => state.UserInfo.userInfo);

    const [dateRange, setDateRange] = useState<DateRange>({
        startDate: getStartOfDate(),
        endDate: getEndOfDate(),
    });
    const [targetIdOrName, setTargetIdOrName] = useState('');
    const [selectedWardList, setSelectedWardList] = useState<string[]>(() => initWardList(userInfo.groupNums));
    const [sensorState, setSensorState] = useState<string>(INIT_SENSOR_STATE);

    const wardMapInfo = useAppSelector(state => state.DepartmentsInfo.wardMapInfo);

    // 알림 이력 조회
    const [history, setHistory] = useState<{ rows: EmergencyAlertLog[] }>({ rows: [] });

    const { state, promise } = useAsync({
        fixedParam: {
            categoryCode: CATEGORY_CODE.PATIENT,
            pageSize: 50,
        },
        param: {
            categoryCode: CATEGORY_CODE.PATIENT,
            wardList: initWardList(userInfo.groupNums),
            sensorState,
            ...dateRange,
        },
        promise: getEmergencyAlertLogApi,
        resolve: res => {
            setHistory({
                ...res,
                rows: res.rows.map(log => {
                    return { ...log, regTime: log.regDate };
                }),
            });
        },
        immediate: true,
        deps: [userInfo.groupNums],
    });

    const columns = useColumns(
        [
            {
                Header: t('Date'),
                className: 'text-ellipsis',
                accessor: 'regDate',
                width: 90,
                Cell: ({ value }: { value: number }) => moment(value * 1000).format('YYYY-MM-DD'),
            },
            {
                Header: t('Ward'),
                accessor: 'wardName',
                width: 60,
            },
            {
                Header: t('Room'),
                accessor: 'room',
                width: 60,
            },
            {
                Header: t('Bed'),
                accessor: 'bed',
                width: 60,
            },
            mainCols.targetName({
                Header: t('Patient Name'),
                className: 'text-center',
                width: 100,
            }),
            mainCols.targetId({
                Header: t('Patient Number'),
                className: 'text-center',
                width: 100,
            }),
            {
                Header: t('Vital Signs'),
                className: 'text-ellipsis',
                accessor: 'sensingType',
                width: 110,
                Cell: ({ value }: { value: string }) => <span>{t(SENSING_TYPE_TO_LABEL[value], 'SensingType')}</span>,
            },
            {
                Header: t('Data'),
                accessor: 'currentValue',
                width: 90,
                Cell: ({ value }: { value: number }) => <span>{value}</span>,
            },
            {
                Header: t('Rating'),
                accessor: 'sensingState',
                width: 90,
                Cell: ({ value }: { value: string }) => <span>{t(value)}</span>,
            },
            {
                Header: t('Recognition Time'),
                className: 'text-ellipsis',
                accessor: 'regTime',
                width: 90,
                Cell: ({ value }: { value: number }) => moment(value * 1000).format('HH:mm:ss'),
            },
            mainCols.sensorName({
                Header: t('Device Name'),
                className: 'text-center',
                accessor: 'sensorName',
                width: 110,
            }),
        ],
        t,
        [wardMapInfo],
    );

    const handlePageChange = (pageIndex: number) => {
        const param = state.request;
        promise({ ...param, page: pageIndex });
    };

    return (
        <WidgetCard
            widgetInfo={widgetInfo}
            headerAction={
                ![SCREEN_MODE_EDIT, SCREEN_MODE_PREVIEW].includes(mode ?? '') && (
                    <ExcelExport param={state.request ?? {}} />
                )
            }
            searchFilter={
                <Search
                    dateRange={dateRange}
                    handleDateRange={setDateRange}
                    targetIdOrName={targetIdOrName}
                    handleTargetIdOrName={setTargetIdOrName}
                    selectedWardList={selectedWardList}
                    handleSelectedWardList={setSelectedWardList}
                    sensorState={sensorState}
                    handleSensorState={setSensorState}
                    handleReset={() => {
                        const initialDate = {
                            startDate: getStartOfDate(),
                            endDate: getEndOfDate(),
                        };
                        const initialWardList = initWardList(userInfo.groupNums);
                        setDateRange(initialDate);
                        setTargetIdOrName('');
                        setSelectedWardList(initialWardList);
                        setSensorState(INIT_SENSOR_STATE);
                        promise({
                            ...initialDate,
                            categoryCode: CATEGORY_CODE.PATIENT,
                            wardList: initialWardList,
                            sensorState: INIT_SENSOR_STATE,
                        });
                    }}
                    handleSearchClick={() => {
                        const param: {
                            startDate: UnixTimestamp;
                            endDate: UnixTimestamp;
                            targetIdOrName?: string;
                            wardList?: string[];
                            sensorState?: string;
                        } = {
                            ...dateRange,
                        };
                        if (targetIdOrName.trim()) {
                            param.targetIdOrName = targetIdOrName.trim();
                        }
                        if (selectedWardList.length) {
                            param.wardList = selectedWardList;
                        }
                        param.sensorState = sensorState ?? INIT_SENSOR_STATE;
                        promise({ ...param, categoryCode: CATEGORY_CODE.PATIENT });
                    }}
                />
            }
            bodyClassName={'flx-col h-100 gap-0'}
            {...restProps}
        >
            <Table
                columns={columns}
                data={{ ...history, pageSize: 50 }}
                onPageChange={handlePageChange}
                noDataMessage={t('No anomaly history.')}
                loading={state.isLoading}
            />
            <span className="pnt-txt txt-bold">
                *{' '}
                {t(
                    'Regardless of the patient anomaly notification window, you can check all the history of warning and danger.',
                )}
            </span>
            {children}
        </WidgetCard>
    );
};

export default PatientAnomalyHistory;
