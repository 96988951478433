import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import {
    DisplayedMonitoringPatient,
    setSelectedPatient,
    useBiometricInformationDashboardDispatchContext,
} from '../../../slice';
import {
    CLASSNAME_CRITICAL,
    CLASSNAME_LOST_SIGNAL,
    CLASSNAME_OUT_OF_RANGE,
    CLASSNAME_WARNING,
    getStatusClassName,
    STATUS_CRITICAL,
    STATUS_OUTOFRANGE,
    STATUS_WARNING,
    getStatusStyle,
    TEMP_LOST_SIGNAL_TIME,
    getIconClassName,
    getStatusIcon,
    CLASSNAME_NONE_ACTIVE,
    CLASSNAME_MOVEMENT,
} from '../../../util';
import moment from 'moment';
import cx from 'classnames';
import { SENSOR_TYPE_ORDER, SENSOR_TYPE_TO_NAME } from '../../../constants';
import { NullableString } from '@util/type/util';

type PatientCardProps = {
    patient: DisplayedMonitoringPatient;
};
const PatientCard = ({ patient }: PatientCardProps) => {
    const dispatch = useBiometricInformationDashboardDispatchContext();
    const [className, setClassName] = useState(getStatusClassName(patient));

    useEffect(() => {
        setClassName(getStatusClassName(patient));
        if (patient.lastDate) {
            const diff = moment().valueOf() - moment.unix(patient.lastDate).valueOf();
            const bgTimeout = setTimeout(() => {
                setClassName(CLASSNAME_NONE_ACTIVE);
            }, TEMP_LOST_SIGNAL_TIME - diff);
            return () => {
                clearTimeout(bgTimeout);
            };
        }
    }, [patient.lastDate]);

    return (
        <Card
            className={cx(className)}
            onClick={() => {
                dispatch(setSelectedPatient(patient));
            }}
        >
            <div className={'patient-info'}>
                <span className={'patient-room flex-center'}>
                    <div className={'text-ellipsis text-content'} title={`${patient.room} ${patient.bed}`}>
                        {patient.room} {patient.bed}
                    </div>
                </span>
                <span className={'patient-name flex-center'}>
                    <div className={'text-ellipsis text-content'} title={patient.targetName}>
                        {patient.targetName}
                    </div>
                </span>
                <span className={'patient-status-wrapper'}>
                    <span className={cx('patient-status', getIconClassName(patient))}></span>
                </span>
            </div>
            <div className={'sensor-info'}>
                {SENSOR_TYPE_ORDER.map((sensorType, i) => {
                    return (
                        <Fragment key={sensorType}>
                            <div
                                className={cx(
                                    'sensor-item',
                                    getOccurItem(className, patient.recentSensorStates?.[sensorType]) && 'occur-item',
                                )}
                            >
                                <div className={'sensor-name'}>{SENSOR_TYPE_TO_NAME[sensorType]}</div>
                                <div className={'sensor-value'}>
                                    {patient.recentSensorValues?.[sensorType] ?? 'N/A'}
                                </div>
                            </div>
                            {i !== 2 && <div className={'pnt-divider vertical-line m-0'} />}
                        </Fragment>
                    );
                })}
            </div>
            {/*{[CLASSNAME_LOST_SIGNAL, CLASSNAME_NONE_ACTIVE].includes(className) && (*/}
            {/*    <div className={'none-active-status-block'} />*/}
            {/*)}*/}
        </Card>
    );
};

const getOccurItem = (sensorClassName: string, sensorState?: NullableString) => {
    if (sensorClassName === CLASSNAME_CRITICAL && sensorState === STATUS_CRITICAL) {
        return true;
    }
    if (sensorClassName === CLASSNAME_WARNING && sensorState === STATUS_WARNING) {
        return true;
    }
    if (sensorClassName === CLASSNAME_OUT_OF_RANGE && sensorState === STATUS_OUTOFRANGE) {
        return true;
    }
    return false;
};

const Card = styled.div`
    cursor: pointer;
    position: relative;
    padding: 5px;
    gap: 5px;
    border: 1px solid var(--depth-8);
    ${getStatusStyle('#root .app-container &')};
    display: grid;
    grid-template-rows: 20px 53px;
    .none-active-status-block {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: var(--depth-9);
        opacity: 0.8;
        z-index: 1;
    }
    // &.${CLASSNAME_LOST_SIGNAL}, &.${CLASSNAME_NONE_ACTIVE} {
    //     filter: contrast(0.4) opacity(0.7);
    // }
    #root .app-container & .patient-info {
        position: relative;
        display: flex;
        justify-content: space-between;
        gap: 10px;
        font-size: 14px;
        letter-spacing: -0.5px;
        padding: 0 5px;
        .patient-room {
            justify-content: flex-start;
            max-width: 50px;
            line-height: 15px;
            letter-spacing: -0.5px;
            flex: 0 0 40px;
            .text-content {
                font-weight: 700;
            }
        }
        .patient-name {
            justify-content: flex-start;
            opacity: 0.75;
            max-width: 70px;
            line-height: 1.14;
            flex: 0 0 80px;
            .text-content {
                font-weight: 500;
            }
        }
        .patient-status-wrapper {
            display: inline-block;
            width: 20px;
            height: 20px;
            .patient-status {
                display: inline-block;
                width: 20px;
                height: 20px;
                opacity: 0.5;
                background-color: var(--base-txt);
                mask-repeat: no-repeat;
                mask-size: cover;
                mask-position: center;
                ${getStatusIcon()}
            }
        }
    }
    #root .app-container &.${CLASSNAME_CRITICAL} .patient-info,
    #root .app-container &.${CLASSNAME_WARNING} .patient-info {
        .patient-status-wrapper .patient-status {
            opacity: 0.2;
            background-color: black;
        }
        .patient-name {
            opacity: 0.6;
        }
    }
    #root .app-container &.${CLASSNAME_NONE_ACTIVE} {
        .patient-info {
            .patient-room,
            .patient-name {
                opacity: 0.5;
            }

            .patient-status {
                background-color: #ffffff;
                opacity: 0.7;
            }
        }
        .sensor-info {
            opacity: 0.2;
        }
    }
    #root .app-container &.${CLASSNAME_MOVEMENT} {
        .patient-info {
            .patient-status {
                background-color: #ffffff;
                opacity: 0.7;
            }
        }
        .sensor-info {
            opacity: 0.4;
        }
    }
    #root .app-container & .sensor-info {
        width: 100%;
        height: 100%;
        outline: 1px solid #00000005;
        border-radius: 2px;
        padding: 2px;
        background-color: #0000001a;

        display: grid;
        grid-template-columns: 50px min-content 50px min-content 66px;
        justify-content: space-between;

        .sensor-item {
            padding: 5px;
            gap: 5px;
            border-radius: 2px;
            display: flex;
            flex-direction: column;
            .sensor-name,
            .sensor-value {
                white-space: nowrap;
            }
            .sensor-name {
                font-size: 12px;
                font-weight: 400;
                line-height: 1.2;
                letter-spacing: -0.5px;
                opacity: 0.6;
            }
            .sensor-value {
                font-size: 22px;
                font-weight: 800;
                line-height: 0.9;
                z-index: 1;
            }
        }

        .pnt-divider {
            height: 14px;
            transform: translateY(200%);
            background-color: #ffffff;
            opacity: 0.35;
            //width: 2px;
        }
    }

    #root .app-container &.${CLASSNAME_CRITICAL} .sensor-info {
        background-color: #b3243e;
        .sensor-item * {
            color: #ffffff;
        }
        .sensor-item.occur-item {
            background: #661423;
        }
        .pnt-divider {
            background-color: #000000;
            opacity: 0.15;
        }
    }
    #root .app-container &.${CLASSNAME_WARNING} .sensor-info {
        background-color: #bf9b23;
        .sensor-item * {
            color: #ffffff;
        }
        .sensor-item.occur-item {
            background: #665512;
        }
        .pnt-divider {
            background-color: #000000;
            opacity: 0.15;
        }
    }
    #root .app-container &.${CLASSNAME_OUT_OF_RANGE} .sensor-info {
        background-color: #5d569e;
        .sensor-item.occur-item {
            background: #35296e;
        }
    }
`;

export default PatientCard;
