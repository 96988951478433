import React, { useMemo, useState } from 'react';
import { useAsync, useConfirm, useTranslation } from '@hooks';
import { Label, Select, SearchableSelect, ConfirmModal } from '@components';
import { WidgetProps } from '../../staticInfo';
import WidgetCard from '../../Components/WidgetCard';
import { Trans } from 'react-i18next';
import {
    getPatientAnomalyAlertSettingsApi,
    PatientAnomalyAlertSettings,
    updatePatientAnomalyAlertSettingsApi,
} from '@api/sh/patientAnomalyAlertSetting';
import { YN } from '@util/type/util';
import Button from '@components/Button';
import styled from 'styled-components';

const OPTIONS_USAGE = [
    { value: 'Y', label: 'Enabled' },
    { value: 'N', label: 'Disabled' },
];

const OPTIONS_SELECT_RESTRICTION_ZONES = [
    { value: 'Y', label: 'Select' },
    { value: 'N', label: 'Not Select' },
];

const OPTIONS_COUNT = [
    { value: 12, label: 12 },
    { value: 20, label: 20 },
    { value: 30, label: 30 },
];

const PatientAnomalyAlertSetting = ({ widgetInfo, children, ...restProps }: WidgetProps) => {
    const t = useTranslation('PatientAnomalyAlertSetting');
    const [settings, setSettings] = useState<PatientAnomalyAlertSettings>({
        alertActive: 'Y',
        alertOpenCount: 12,
        alertRestrictionActive: 'N',
        alertRestrictionZones: [],
    });

    const usageOptions = useMemo(() => {
        return OPTIONS_USAGE.map(({ value, label }) => ({ value, label: t(label) }));
    }, [t]);

    const selectRestrictionZonesOptions = useMemo(() => {
        return OPTIONS_SELECT_RESTRICTION_ZONES.map(({ value, label }) => ({ value, label: t(label) }));
    }, [t]);

    useAsync({
        promise: getPatientAnomalyAlertSettingsApi,
        immediate: true,
        resolve: res => {
            setSettings(res);
        },
    });

    const { promise: updatePatientAnomalyAlertSettings } = useAsync({
        promise: updatePatientAnomalyAlertSettingsApi,
        resolve: () => {
            saveSuccessModalProps.toggleModal();
        },
        reject: () => {
            saveFailureModalProps.toggleModal();
        },
    });

    const saveConfirmModalProps = useConfirm({
        confirmText: t('Do you want to save?'),
        okCallback: () => {
            updatePatientAnomalyAlertSettings(settings);
        },
    });

    const saveSuccessModalProps = useConfirm({
        confirmText: t('Save is complete.'),
        removeCancel: true,
    });

    const saveFailureModalProps = useConfirm({
        confirmText: t('The save failed.'),
        removeCancel: true,
    });

    return (
        <WidgetCard widgetInfo={widgetInfo} {...restProps}>
            <ContentWrapper>
                <section>
                    <Label
                        name={
                            <span>
                                <div>{t('Enable or disable the patient anomaly alert popup')}</div>
                                <small>
                                    * {t('You can choose to enable or disable the patient anomaly alert popup.')}
                                </small>
                            </span>
                        }
                        value={
                            <Select
                                options={usageOptions}
                                value={usageOptions.find(({ value }) => value === settings.alertActive)}
                                onChange={selected => {
                                    const selectedItem = selected as { value: YN; label: string };
                                    if (selected) {
                                        setSettings({ ...settings, alertActive: selectedItem.value });
                                    }
                                }}
                            />
                        }
                    />
                    <div className="pnt-divider horizon-line" />
                    <Label
                        name={
                            <span>
                                <div>{t('Patient anomaly alert popup open time')}</div>
                                <small>
                                    * {t('Set the anomaly persistence criteria that triggers the alert popup.')}
                                </small>
                            </span>
                        }
                        value={
                            <span>
                                <div className={'d-flex align-items-center'}>
                                    <Trans
                                        i18nKey={'PatientAnomalyAlertSetting'}
                                        components={[
                                            <Select
                                                disabled={settings.alertActive === 'N'}
                                                className={'mx-1'}
                                                options={OPTIONS_COUNT}
                                                value={OPTIONS_COUNT.find(
                                                    ({ value }) => value === settings.alertOpenCount,
                                                )}
                                                onChange={selected => {
                                                    const selectedItem = selected as { value: number; label: number };
                                                    if (selected) {
                                                        setSettings({
                                                            ...settings,
                                                            alertOpenCount: selectedItem.value,
                                                        });
                                                    }
                                                }}
                                            />,
                                        ]}
                                    >
                                        {t('Pop up an alert after <0></0> patient anomalies.')}
                                    </Trans>
                                </div>
                                <small>
                                    *{' '}
                                    {t(
                                        'Patient anomaly counts average 5 seconds, so 12 counts in a row means that an anomaly state has been present for approximately 60 seconds.',
                                    )}
                                </small>
                            </span>
                        }
                    />
                    <div className="pnt-divider horizon-line" />
                    <Label
                        name={
                            <span>
                                <div>{t('Select an patient anomaly alert restricted area')}</div>
                                <small>* {t("Disable patient anomaly alert when you're in certain areas.")}</small>
                            </span>
                        }
                        value={
                            <>
                                <Select
                                    disabled={settings.alertActive === 'N'}
                                    options={selectRestrictionZonesOptions}
                                    value={usageOptions.find(({ value }) => value === settings.alertRestrictionActive)}
                                    onChange={selected => {
                                        const selectedItem = selected as { value: YN; label: string };
                                        if (selected) {
                                            setSettings({ ...settings, alertRestrictionActive: selectedItem.value });
                                        }
                                    }}
                                />
                                <SearchableSelect
                                    disabled={settings.alertActive === 'N' || settings.alertRestrictionActive === 'N'}
                                    valueKey={'fcGroupNum'}
                                    labelKey={'fcGroupName'}
                                    data={settings.alertRestrictionZones}
                                    value={settings.alertRestrictionZones.filter(fcGroup => fcGroup.isRestrict === 'Y')}
                                    onChange={selected => {
                                        const selectedItems = selected as PatientAnomalyAlertSettings['alertRestrictionZones'];
                                        setSettings({
                                            ...settings,
                                            alertRestrictionZones: settings.alertRestrictionZones.map(fcGroup => {
                                                if (
                                                    selectedItems.find(
                                                        selected => selected.fcGroupNum === fcGroup.fcGroupNum,
                                                    )
                                                ) {
                                                    return { ...fcGroup, isRestrict: 'Y' };
                                                } else {
                                                    return { ...fcGroup, isRestrict: 'N' };
                                                }
                                            }),
                                        });
                                    }}
                                />
                            </>
                        }
                    />
                </section>
                <section className={'d-flex justify-content-end gap-2'}>
                    <Button
                        className={'btn-secondary'}
                        onClick={() => {
                            saveConfirmModalProps.toggleModal();
                        }}
                    >
                        {t('Save')}
                    </Button>
                </section>
            </ContentWrapper>
            <ConfirmModal {...saveConfirmModalProps} />
            <ConfirmModal {...saveSuccessModalProps} />
            <ConfirmModal {...saveFailureModalProps} />
            {children}
        </WidgetCard>
    );
};

const ContentWrapper = styled.div`
    display: grid;
    row-gap: 2rem;
    .label-main {
        flex: 0 0 25rem;
    }
`;

export default PatientAnomalyAlertSetting;
