import { PageInfoParam, RequestHandlerCreator } from '@api/util';
import { reqGet, reqPost } from '@api/index';
import {
    Nullable,
    NullableNumber,
    NullableString,
    NullableUnixTimestamp,
    PageInfo,
    UnixTimestamp,
    YN,
} from '@util/type/util';

type RoomListParam = PageInfoParam & {
    wardList: string[];
};

type RoomListInfo = PageInfo<{ ward: string; room: string }>;

export const getRoomListApi: RequestHandlerCreator<RoomListInfo, RoomListParam> = param =>
    reqGet('/v3/api/sh01/realtime/patients/monitoring/rooms', { ...param, isAll: 'Y' });

type MonitoringPatientsParam = PageInfoParam & {
    modelCodeList?: string[];
    wardList?: string[];
    roomList?: string[];
    isBookmark: YN;
    isMapping: YN;
};

type SensorStatus = 'CRITICAL' | 'WARNING' | 'NORMAL' | 'NONE' | 'OUTOFRANGE';
export type MappedSensor = {
    sensorName: string;
    deviceNum: number;
    modelCode: string;
    lastDate: NullableUnixTimestamp;
    recentSensorValues: {
        TEMPERATURE: NullableNumber;
        BATTERY: NullableNumber;
        RESPIRATION_RATE: NullableNumber;
        HEARTBEAT: NullableNumber;
    };
    recentSensorStates: {
        TEMPERATURE: Nullable<SensorStatus>;
        BATTERY: Nullable<SensorStatus>;
        RESPIRATION_RATE: Nullable<SensorStatus>;
        HEARTBEAT: Nullable<SensorStatus>;
    };
    info: Nullable<{ motion: number; leadOff: number }>;
};

export type MonitoringPatient = {
    targetNum: number;
    targetName: string;
    targetId: string;
    fcList: { fcNum: number; fcName: string }[];
    ward: NullableString;
    room: NullableString;
    bed: NullableString;
    isBookmark: YN;
    mappedSensorList: MappedSensor[];
};

type MonitoringPatientsInfo = PageInfo<MonitoringPatient>;

export const getMonitoringPatentsApi: RequestHandlerCreator<MonitoringPatientsInfo, MonitoringPatientsParam> = param =>
    reqGet('/v3/api/sh01/realtime/patients/monitoring', { ...param, isAll: 'Y' });

type SensorLogInfo = {
    measurementUnits: { [k: string]: string };
    sensingList: {
        regDate: UnixTimestamp[];
    } & {
        [k: string]: number[];
    };
};

type SensorLogInfoParam = {
    targetNum?: number;
    modelCode?: string;
    startDate?: UnixTimestamp;
    endDate?: UnixTimestamp;
};

export const getSensorLogApi: RequestHandlerCreator<SensorLogInfo, SensorLogInfoParam> = param =>
    reqGet('/v3/api/sh01/log/sensor/graph', { ...param });

type EmergencyAlertLogParam = {
    targetIdOrName?: string;
    categoryCode: string;
    wardList?: string[];
    sensorState?: string;
    startDate?: UnixTimestamp;
    endDate?: UnixTimestamp;
};

export type EmergencyAlertLog = {
    regDate: UnixTimestamp;
    ward?: string;
    room?: string;
    bed?: string;
    targetNum: number;
    targetName?: string;
    targetId: string;
    sensingType: string;
    sensingState: string;
    currentValue: string;
    sensorName: string;
};

export const getEmergencyAlertLogApi: RequestHandlerCreator<
    PageInfo<EmergencyAlertLog>,
    EmergencyAlertLogParam
> = param => reqGet('/v3/api/sh01/realtime/emergency/alert/logs', { ...param });

type EmergencyAlertLogExcelDownloadInfo = {
    comNum: number;
    reqNum: number;
    fileName: string;
    filePath: string;
    dataSettingStatusType: string;
    regDate: UnixTimestamp;
    exportLogType: string;
};

type EmergencyAlertLogExcelDownloadInfoParam = EmergencyAlertLogParam & {
    excelDownloadRequest: {
        zipFileName: string;
        fileName: string;
        columnMetas: Record<string, string>;
    };
};

export const getEmergencyAlertLogExcelApi: RequestHandlerCreator<
    EmergencyAlertLogExcelDownloadInfo,
    EmergencyAlertLogExcelDownloadInfoParam
> = ({ excelDownloadRequest, ...restParam }) =>
    reqPost('/v3/api/sh01/realtime/emergency/alert/logs/export', excelDownloadRequest, restParam);

type EmergencyAlertOpenLogParam = {
    targetIdOrName?: string;
    categoryCode: string;
    wardList?: string[];
    startDate?: UnixTimestamp;
    endDate?: UnixTimestamp;
};

export type EmergencyAlertOpenLog = {
    regDate: UnixTimestamp;
    ward?: string;
    room?: string;
    bed?: string;
    targetNum: number;
    targetName?: string;
    targetId: string;
    heartbeat?: number;
    temperature?: number;
    respirationRate?: number;
    fcNameList?: string[];
    floorName?: string;
};

export const getEmergencyAlertOpenLogApi: RequestHandlerCreator<
    PageInfo<EmergencyAlertOpenLog>,
    EmergencyAlertOpenLogParam
> = param => reqGet('/v3/api/sh01/realtime/emergency/alert/open/logs', { ...param });

type EmergencyAlertOpenLogExcelDownloadInfo = {
    comNum: number;
    reqNum: number;
    fileName: string;
    filePath: string;
    dataSettingStatusType: string;
    regDate: UnixTimestamp;
    exportLogType: string;
};

type EmergencyAlertOpenLogExcelDownloadInfoParam = EmergencyAlertOpenLogParam & {
    excelDownloadRequest: {
        zipFileName: string;
        fileName: string;
        columnMetas: Record<string, string>;
    };
};

export const getEmergencyAlertOpenLogExcelApi: RequestHandlerCreator<
    EmergencyAlertOpenLogExcelDownloadInfo,
    EmergencyAlertOpenLogExcelDownloadInfoParam
> = ({ excelDownloadRequest, ...restParam }) =>
    reqPost('/v3/api/sh01/realtime/emergency/alert/open/logs/export', excelDownloadRequest, restParam);
