import React from 'react';
import cx from 'classnames';
import { Nullable, NullableNumber, NullableString } from '@util/type/util';
import styled from 'styled-components';

const STATUS_COLOR: { [key: string]: { BG: string; COLOR: string } } = {
    WARNING: { BG: 'bg-warning', COLOR: '' },
    CRITICAL: { BG: 'bg-danger', COLOR: 'color-white-75' },
    OUTOFRANGE: { BG: 'bg-secondary bg-l-4', COLOR: 'color-white-75' },
    undefined: { BG: 'bg-gray bg-light', COLOR: '' },
    null: { BG: 'bg-gray bg-light', COLOR: '' },
    NONE: { BG: 'bg-gray bg-light', COLOR: '' },
};

type SensorType<T> = 'HEARTBEAT' | 'SPO2' | 'TEMPERATURE' | 'BATTERY' | T;

export type SensorStates<T extends string> = {
    [K in SensorType<T>]?: NullableString;
};

const SensorValueCell = <T extends string>({
    value,
    states,
    sensorType,
}: {
    value?: NullableNumber;
    states?: Nullable<SensorStates<T>>;
    sensorType: T;
}) => {
    const state = states ? String(states[sensorType]) : 'null';
    return (
        <StyledCell
            className={cx('w-100 h-100 flex-center pnt-txt s-8', STATUS_COLOR[state]?.BG, STATUS_COLOR[state]?.COLOR)}
        >
            {value}
        </StyledCell>
    );
};

const StyledCell = styled.div`
    #root .app-container &.pnt-txt.color-white-75 {
        color: rgba(255, 255, 255, 0.75);
    }
`;

export default SensorValueCell;
