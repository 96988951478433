import { createContext, Dispatch, useContext } from 'react';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ActionType } from '@components/ContextProvider';
import { Nullable, NullableNumber, NullableString, YN } from '@util/type/util';
import { MappedSensor, MonitoringPatient } from '@api/sh/biometricInformationMonitoring';
import { MODEL_CODE_MEZOO_SMARTPATCH } from '@util/staticData/modelCode';
import { roundNumber } from '@util/common/util';

type MonitoringFilter = {
    wardList?: string[];
    roomList?: string[];
    isMapping?: YN;
    isBookmark?: YN;
};

export type DisplayedMonitoringPatient = Omit<MonitoringPatient, 'mappedSensorList'> & {
    fcNames: string;
} & Partial<MappedSensor>;

export type MonitoringGroup = {
    room: NullableString;
    roomName: NullableString;
    ward: NullableString;
    wardName: NullableString;
    patients: DisplayedMonitoringPatient[];
};

export const initialState: {
    selectedPatient: Nullable<DisplayedMonitoringPatient>;
    monitoringFilter: MonitoringFilter;
    monitoringGroupList: MonitoringGroup[];
} = {
    selectedPatient: null,
    monitoringFilter: {
        wardList: [],
        roomList: [],
        isMapping: 'Y',
        isBookmark: 'N',
    },
    monitoringGroupList: [],
};

export const BiometricInformationMonitoringStateContext = createContext(initialState);
export const BiometricInformationMonitoringDispatchContext = createContext<Dispatch<ActionType> | null>(null);

export const useBiometricInformationMonitoringStateContext = () =>
    useContext(BiometricInformationMonitoringStateContext);
export const useBiometricInformationMonitoringDispatchContext = () =>
    useContext(BiometricInformationMonitoringDispatchContext)!;

const biometricInformationMonitoringSlice = createSlice({
    name: 'sh/biometricInformationMonitoring',
    initialState,
    reducers: {
        setSelectedPatient: (state, action) => {
            state.selectedPatient = action.payload;
        },
        setMonitoringFilter: (state, action) => {
            const newFilter = action.payload;
            state.monitoringFilter = { ...state.monitoringFilter, ...newFilter };
        },
        setMonitoringPatients: (state, action) => {
            const { wardMapInfo, patients } = action.payload;
            const convert = (value: NullableString) => {
                // 숫자로 변환이 가능하면 숫자로 변환, 불가능하면 원래 문자열 그대로 반환
                return isNaN(Number(value)) ? value : Number(value);
            };
            patients.sort((a: MonitoringPatient, b: MonitoringPatient) => {
                const wardA = convert(a.ward);
                const wardB = convert(b.ward);

                // 1. ward 값을 비교 (null, undefined, 빈 문자열은 뒤로 배치)
                if (!wardA) return 1;
                if (!wardB) return -1;

                if (wardA < wardB) return -1;
                if (wardA > wardB) return 1;

                const roomA = convert(a.room);
                const roomB = convert(b.room);

                // 2. ward 값이 같다면 room 값을 비교 (null, undefined, 빈 문자열은 뒤로 배치)
                if (!roomA) return 1;
                if (!roomB) return -1;

                if (roomA < roomB) return -1;
                if (roomA > roomB) return 1;

                // 3. ward와 room 값이 같다면 0 반환 (변경 없음)
                return 0;
            });
            state.monitoringGroupList = patients.reduce((acc: MonitoringGroup[], patient: MonitoringPatient) => {
                const foundGroup = acc.find(group => group.ward === patient.ward && group.room === patient.room);
                const { mappedSensorList, ...restPatientInfo } = patient;
                const sensorToDisplay = mappedSensorList.find(
                    sensorInfo => sensorInfo.modelCode === MODEL_CODE_MEZOO_SMARTPATCH,
                );
                if (sensorToDisplay?.recentSensorValues && sensorToDisplay?.recentSensorStates) {
                    getKeepSensorType(sensorToDisplay.recentSensorValues).forEach(sensorType => {
                        sensorToDisplay.recentSensorValues[sensorType] = null;
                        sensorToDisplay.recentSensorStates[sensorType] = null;
                    });
                    roundSensingValues(sensorToDisplay.recentSensorValues);
                }
                const newPatient: DisplayedMonitoringPatient = {
                    ...restPatientInfo,
                    ...sensorToDisplay,
                    fcNames: getFcNames(patient.fcList),
                };
                if (foundGroup) {
                    foundGroup.patients.push(newPatient);
                } else {
                    acc.push({
                        room: patient.room,
                        roomName: patient.room,
                        ward: patient.ward,
                        wardName: patient.ward ? wardMapInfo[patient.ward] : null,
                        patients: [newPatient],
                    });
                }
                return acc;
            }, []);
        },
        updatePatientInfo: (state, action: PayloadAction<DisplayedMonitoringPatient>) => {
            const newPatient = action.payload;
            const { ward, room, targetNum, isBookmark } = newPatient;
            const { isBookmark: bookmarkFilter } = state.monitoringFilter;
            state.monitoringGroupList = state.monitoringGroupList.reduce(
                (accPatientGroup: MonitoringGroup[], patientGroup) => {
                    if (ward === patientGroup.ward && room === patientGroup.room) {
                        const newPatientGroup = {
                            ...patientGroup,
                            patients: patientGroup.patients.reduce(
                                (accPatients: DisplayedMonitoringPatient[], patient) => {
                                    if (patient.targetNum === targetNum) {
                                        if (!(bookmarkFilter === 'Y' && isBookmark === 'N')) {
                                            accPatients.push(newPatient);
                                        }
                                    } else {
                                        accPatients.push(patient);
                                    }
                                    return accPatients;
                                },
                                [],
                            ),
                        };
                        if (newPatientGroup.patients.length) {
                            accPatientGroup.push(newPatientGroup);
                        }
                    } else {
                        accPatientGroup.push(patientGroup);
                    }
                    return accPatientGroup;
                },
                [],
            );
        },
        updateSensingData: (state, action) => {
            const { ward, room, targetNum, ...sensingData } = action.payload;
            state.monitoringGroupList = state.monitoringGroupList.map(patientGroup => {
                if (patientGroup.ward === ward && patientGroup.room === room) {
                    return {
                        ...patientGroup,
                        patients: patientGroup.patients.map(patient => {
                            if (patient.targetNum === targetNum) {
                                if (sensingData.recentSensorValues && sensingData.recentSensorStates) {
                                    getKeepSensorType(sensingData.recentSensorValues).forEach(sensorType => {
                                        sensingData.recentSensorValues[sensorType] =
                                            patient.recentSensorValues?.[sensorType] ?? null;
                                        sensingData.recentSensorStates[sensorType] =
                                            patient.recentSensorStates?.[sensorType] ?? null;
                                    });
                                    roundSensingValues(sensingData.recentSensorValues);
                                }
                                return { ...patient, ...sensingData };
                            }
                            return patient;
                        }),
                    };
                }
                return patientGroup;
            });
        },
        updateFenceData: (state, action) => {
            const { ward, room, targetNum, fcList } = action.payload;
            state.monitoringGroupList = state.monitoringGroupList.map(patientGroup => {
                if (patientGroup.ward === ward && patientGroup.room === room) {
                    return {
                        ...patientGroup,
                        patients: patientGroup.patients.map(patient => {
                            if (patient.targetNum === targetNum) {
                                return { ...patient, fcList, fcNames: getFcNames(fcList) };
                            }
                            return patient;
                        }),
                    };
                }
                return patientGroup;
            });
        },
        updateMappedSensor: (state, action) => {
            const { ward, room, targetNum, sensorNum, sensorName, modelCode } = action.payload;
            state.monitoringGroupList = state.monitoringGroupList.map(patientGroup => {
                if (patientGroup.ward === ward && patientGroup.room === room) {
                    return {
                        ...patientGroup,
                        patients: patientGroup.patients.map(patient => {
                            if (patient.targetNum === targetNum) {
                                // sensorName
                                // deviceNum
                                // modelCode
                                // lastDate
                                // recentSensorValues
                                // recentSensorStates
                                const newSensorInfo: Partial<MappedSensor> = {
                                    deviceNum: sensorNum,
                                    sensorName,
                                    modelCode,
                                };
                                if (!sensorNum) {
                                    newSensorInfo.lastDate = null;
                                    newSensorInfo.recentSensorValues = {
                                        HEARTBEAT: null,
                                        BATTERY: null,
                                        RESPIRATION_RATE: null,
                                        TEMPERATURE: null,
                                    };
                                    newSensorInfo.recentSensorStates = {
                                        HEARTBEAT: null,
                                        BATTERY: null,
                                        RESPIRATION_RATE: null,
                                        TEMPERATURE: null,
                                    };
                                }
                                return { ...patient, ...newSensorInfo };
                            }
                            return patient;
                        }),
                    };
                }
                return patientGroup;
            });
        },
    },
});

const getFcNames = (fcList: { fcNum: number; fcName: string }[]) => fcList.map(geofence => geofence.fcName).join(', ');

type SensorType = keyof MappedSensor['recentSensorValues'];
const SENSOR_TYPE_ZERO_TO_KEEP: SensorType[] = ['HEARTBEAT', 'RESPIRATION_RATE'];
const getKeepSensorType = (sensorValues?: Nullable<{ [sensorType in SensorType]: NullableNumber }>) => {
    return SENSOR_TYPE_ZERO_TO_KEEP.filter(sensorType => sensorValues?.[sensorType] === 0);
};

const roundSensingValue = (
    sensorType: SensorType,
    decimalPlace: number,
    sensorValues?: Nullable<{ [sensorType in SensorType]: NullableNumber }>,
) => {
    if (sensorValues && typeof sensorValues[sensorType] === 'number') {
        sensorValues[sensorType] = roundNumber(sensorValues[sensorType], decimalPlace);
    }
};

const roundSensingValues = (sensorValues?: Nullable<{ [sensorType in SensorType]: NullableNumber }>) => {
    roundSensingValue('TEMPERATURE', 1, sensorValues);
    roundSensingValue('RESPIRATION_RATE', 0, sensorValues);
    roundSensingValue('HEARTBEAT', 0, sensorValues);
};

export const {
    setSelectedPatient,
    setMonitoringFilter,
    setMonitoringPatients,
    updatePatientInfo,
    updateSensingData,
    updateFenceData,
    updateMappedSensor,
} = biometricInformationMonitoringSlice.actions;

export default biometricInformationMonitoringSlice;
