import React, { useEffect, useState } from 'react';
import { Button, ConfirmModal } from '@components';
import { useConfirm, useTranslation } from '@hooks';
import {
    DisplayedMonitoringPatient,
    updatePatientInfo,
    useBiometricInformationMonitoringDispatchContext,
} from '../../slice';
import Bookmark from '../../../../Components/Bookmark';
import { NullableUnixTimestamp, YN } from '@util/type/util';
import cx from 'classnames';
import moment from 'moment';
import DeviceInfoModal from '../MappingModal/DeviceInfoModal';

export const isMapped = ({ deviceNum }: DisplayedMonitoringPatient) => {
    return !!deviceNum;
};

export const SensorNameCell = ({
    value,
    row: { original },
}: {
    value: string;
    row: {
        original: DisplayedMonitoringPatient;
    };
}) => {
    const t = useTranslation('BiometricInformationMonitoring');
    const [showDeviceMappingModal, setShowDeviceMappingModal] = useState(false);

    const handleToggleMappingModal = () => {
        setShowDeviceMappingModal(prev => !prev);
    };

    return (
        <>
            {isMapped(original) ? (
                <Button
                    className={'btn-trans text-decoration-underline text-ellipsis d-block'}
                    title={value}
                    onClick={e => {
                        e.stopPropagation();
                        handleToggleMappingModal();
                    }}
                >
                    {value}
                </Button>
            ) : (
                <Button
                    className={'btn-line'}
                    onClick={e => {
                        e.stopPropagation();
                        handleToggleMappingModal();
                    }}
                >
                    {t('Device mapping')}
                </Button>
            )}
            <DeviceInfoModal
                isOpen={showDeviceMappingModal}
                toggleModal={handleToggleMappingModal}
                targetNum={original.targetNum}
                deviceNum={original.deviceNum}
            />
        </>
    );
};

export const BookmarkCell = ({
    value,
    row: { original },
}: {
    value: YN;
    row: {
        original: DisplayedMonitoringPatient;
    };
}) => {
    const t = useTranslation('BiometricInformationMonitoring');
    const dispatch = useBiometricInformationMonitoringDispatchContext();
    const bookmarkFailureModalProps = useConfirm({
        confirmText: t('Something has changed.', 'ConfirmModal'),
        removeCancel: true,
    });
    const handleBookmarkChange = () => {
        dispatch(updatePatientInfo({ ...original, isBookmark: value === 'Y' ? 'N' : 'Y' }));
    };
    return (
        <>
            <Bookmark
                isBookmark={value}
                targetNum={original.targetNum}
                successCallback={handleBookmarkChange}
                failureCallback={bookmarkFailureModalProps.toggleModal}
            />
            <ConfirmModal {...bookmarkFailureModalProps} />
        </>
    );
};

export const RegisteredDateCell = ({ value }: { value: NullableUnixTimestamp }) => {
    const [className, setClassName] = useState('');

    useEffect(() => {
        const TEMP_LOST_SIGNAL_TIME = 60000;
        if (value) {
            const diff = moment().valueOf() - moment.unix(value).valueOf();
            if (diff > TEMP_LOST_SIGNAL_TIME) {
                setClassName('bg-gray bg-light');
            } else {
                setClassName('');
                const bgTimeout = setTimeout(() => {
                    setClassName('bg-gray bg-light');
                }, TEMP_LOST_SIGNAL_TIME - diff);
                return () => {
                    clearTimeout(bgTimeout);
                };
            }
        }
    }, [value]);

    return value ? (
        <div className={cx('w-100 h-100 flex-center pnt-txt s-8', className)}>
            {moment.unix(value).format('YYYY-MM-DD HH:mm:ss')}
        </div>
    ) : (
        ''
    );
};
