import React, { useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useBiometricInformationMonitoringStateContext } from '../../../slice';
import { useAsync, useTranslation, useAppSelector } from '@hooks';
import cx from 'classnames';
import { ScatterDataPoint } from 'chart.js';
import { SENSOR_TYPE_ORDER, SENSOR_TYPE_TO_NAME, TICK_LIMITS } from '../../../constants';
import VitalSignsChart from './VitalSignsChart';
import { Timestamp, UnixTimestamp, YN } from '@util/type/util';
import { getStartOfDate } from '@util/date';
import moment from 'moment';
import styled from 'styled-components';
import { getSensorLogApi } from '@api/sh/biometricInformationMonitoring';
import { MODEL_CODE_MEZOO_SMARTPATCH } from '@util/staticData/modelCode';
import { DatePicker } from '@components';
import Button from '@components/Button';

type SensorType = 'HEARTBEAT' | 'RESPIRATION_RATE' | 'TEMPERATURE' | 'BATTERY';
export type ChartSensorType = Exclude<SensorType, 'BATTERY'>;

type ChartDot = {
    currentValue: number;
    measurementUnit: string;
    regDate: number;
    sensingType: string;
    targetId: string;
};

export type ChartData = {
    data: (number | ScatterDataPoint | null)[];
    labels: number[];
    sensorType: ChartSensorType;
    name: string;
    unit: string;
    min: number;
    max: number;
};

export type ChartLoading = {
    HEARTBEAT: boolean;
    RESPIRATION_RATE: boolean;
    TEMPERATURE: boolean;
};

const getInitialChartData = (sensorType: ChartSensorType, name: string) => ({
    data: [],
    labels: [getStartOfDate() * 1000, moment().valueOf()],
    sensorType: sensorType,
    name: name,
    unit: '',
    min: Infinity,
    max: -Infinity,
});

const getProgressInfo = (progressInfo: { progressType: YN; time: UnixTimestamp }) => {
    if (progressInfo.progressType === 'Y') {
        return { startTime: progressInfo.time };
    } else {
        return { endTime: progressInfo.time };
    }
};

const VitalSignsView = () => {
    const t = useTranslation('BiometricInformationMonitoring');
    const [searchDate, setSearchDate] = useState(moment().unix());
    const mode = useAppSelector(state => state.ScreenInfo.mode);
    const { selectedPatient } = useBiometricInformationMonitoringStateContext();
    const [chartData, setChartData] = useState<{
        HEARTBEAT: ChartData;
        RESPIRATION_RATE: ChartData;
        TEMPERATURE: ChartData;
    }>({
        HEARTBEAT: getInitialChartData('HEARTBEAT', t(SENSOR_TYPE_TO_NAME.HEARTBEAT)),
        RESPIRATION_RATE: getInitialChartData('RESPIRATION_RATE', t(SENSOR_TYPE_TO_NAME.RESPIRATION_RATE)),
        TEMPERATURE: getInitialChartData('TEMPERATURE', t(SENSOR_TYPE_TO_NAME.TEMPERATURE)),
    });
    const [chartLoading, setChartLoading] = useState<ChartLoading>({
        HEARTBEAT: true,
        RESPIRATION_RATE: true,
        TEMPERATURE: true,
    });

    const {
        state: { isLoading },
    } = useAsync({
        promise: getSensorLogApi,
        fixedParam: {
            targetNum: selectedPatient?.targetNum,
            modelCode: MODEL_CODE_MEZOO_SMARTPATCH,
            startDate: moment.unix(searchDate).startOf('d').unix(),
            endDate: moment.unix(searchDate).endOf('d').unix(),
        },
        immediate: true,
        deps: [selectedPatient?.targetNum, searchDate],
        resolve: ({ sensingList, measurementUnits }: any, { startDate, endDate }) => {
            if (sensingList) {
                const dateList = sensingList.regDate ?? [];
                SENSOR_TYPE_ORDER.forEach(sensorType => {
                    const list = sensingList[sensorType] ?? [];
                    const stepSize = Math.min(TICK_LIMITS, list.length);
                    const startTime = (startDate ?? dateList[0]) * 1000;
                    const endTime = (endDate ?? dateList[dateList.length - 1]) * 1000;
                    const offset = Math.floor((endTime + 1000 - startTime) / stepSize);
                    const labels: Timestamp[] = [];
                    if (list.length) {
                        labels.push(startTime);
                        for (let i = 0; i < stepSize; i++) {
                            labels.push(startTime + offset * i);
                        }
                        labels.push(endTime);
                    } else {
                        labels.push(getStartOfDate() * 1000, moment().valueOf());
                    }

                    setChartData(prev => {
                        return {
                            ...prev,
                            [sensorType]: list.reduce(
                                (acc: ChartData, currentValue: number, idx: number) => {
                                    acc.data.push({
                                        x: dateList[idx] * 1000,
                                        y: currentValue,
                                    });
                                    acc.min = Math.min(acc.min, currentValue);
                                    acc.max = Math.max(acc.max, currentValue);
                                    return acc;
                                },
                                {
                                    data: [],
                                    labels,
                                    sensorType,
                                    name: t(SENSOR_TYPE_TO_NAME[sensorType]),
                                    unit: measurementUnits[sensorType] ?? '',
                                    min: Infinity,
                                    max: -Infinity,
                                },
                            ),
                        };
                    });
                });
            }
        },
        reject: err => {
            setChartLoading({ HEARTBEAT: false, RESPIRATION_RATE: false, TEMPERATURE: false });
            console.error(err);
        },
    });

    return (
        <VitalSignsWrapper className={cx('p-3 border border-depth-7')}>
            <div className={'chart-header'}>
                <span className={'pnt-txt txt-dot txt-bold s-6'}>{t('Daily graphs')}</span>
                <span style={{ width: '200px' }}>
                    <DatePicker
                        withoutTime
                        maxDate={moment().valueOf()}
                        value={searchDate}
                        handleChange={selected => {
                            if (selected) {
                                setSearchDate(selected);
                            } else {
                                setSearchDate(moment().unix());
                            }
                        }}
                    />
                </span>
                <div className={'flex-center'}>
                    <Button
                        className="btn-icon-only btn-gray"
                        iconName="keyboard_arrow_left"
                        onClick={() => {
                            setSearchDate(moment.unix(searchDate).subtract(1, 'day').unix());
                        }}
                    />
                    <Button
                        className={'btn-icon-only btn-gray ml-1'}
                        iconName="keyboard_arrow_right"
                        onClick={() => {
                            setSearchDate(moment.unix(searchDate).add(1, 'day').unix());
                        }}
                        disabled={moment.unix(searchDate).isSame(moment(), 'day')}
                    />
                </div>
            </div>
            <PerfectScrollbar className={'chart-container'}>
                {SENSOR_TYPE_ORDER.map(sensorType => (
                    <VitalSignsChart
                        key={sensorType}
                        chartData={chartData[sensorType]}
                        isLoading={isLoading || chartLoading[sensorType]}
                        setLoading={setChartLoading}
                    />
                ))}
            </PerfectScrollbar>
        </VitalSignsWrapper>
    );
};

const VitalSignsWrapper = styled.div`
    display: grid;
    grid-template-rows: auto;
    row-gap: 2rem;

    & .chart-header {
        display: grid;
        grid-template-columns: max-content 200px max-content;
        column-gap: 1.5rem;
    }
    & .chart-container {
        display: grid;
        grid-auto-rows: min-content;
        row-gap: 1rem;
    }
`;

export default VitalSignsView;
