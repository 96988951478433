import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import Legends from './Legends';
import MainView from './MainView';
import {
    updateSensingData,
    useBiometricInformationDashboardDispatchContext,
    useBiometricInformationDashboardStateContext,
} from '../../slice';
import { useAsync } from '@hooks';
import { getMonitoringPatentsApi } from '@api/sh/biometricInformationMonitoring';
import { MODEL_CODE_MEZOO_SMARTPATCH } from '@util/staticData/modelCode';
import { setMonitoringPatients } from '../../slice';
import { arraysAreEqual } from '@util/common/util';
import useSocketEvent from '@util/socket/hooks/useSocketEvent';
import { EVENT_TYPE_NUMERIC_SENSING } from '@reducer/SocketInfo';
import { RealtimeIoTItemNumericSensingData } from '@util/socket/socketData';

const Dashboard = () => {
    const dispatch = useBiometricInformationDashboardDispatchContext();
    const { monitoringFilter, monitoringList } = useBiometricInformationDashboardStateContext();

    useAsync({
        promise: getMonitoringPatentsApi,
        fixedParam: { ...monitoringFilter, modelCodeList: [MODEL_CODE_MEZOO_SMARTPATCH] },
        deps: [monitoringFilter],
        immediate: true,
        keepState: true,
        resolve: response => {
            if (response.rows) {
                dispatch(setMonitoringPatients({ patients: response.rows }));
            }
        },
    });

    const [targetNums, setTargetNums] = useState<number[]>([]);
    const targetNumsRef = useRef<number[]>();
    const socketFilter = useMemo(() => {
        return {
            target: {
                targetNum: {
                    $in: targetNums,
                },
            },
        };
    }, [targetNums]);

    useEffect(() => {
        const newTargetNums = monitoringList.map(patient => patient.targetNum);

        if (!arraysAreEqual(targetNumsRef.current, newTargetNums)) {
            targetNumsRef.current = newTargetNums;
            setTargetNums(newTargetNums);
        }
    }, [monitoringList]);

    useSocketEvent({
        name: EVENT_TYPE_NUMERIC_SENSING,
        filterConfig: socketFilter,
        handler: data => {
            const {
                target,
                sensor,
                sensingState,
                sensingValues,
                unixTime,
                info,
            } = data as RealtimeIoTItemNumericSensingData;
            if (sensor.modelCode === MODEL_CODE_MEZOO_SMARTPATCH) {
                dispatch(
                    updateSensingData({
                        targetNum: target.targetNum,
                        deviceNum: sensor.sensorNum,
                        sensorName: sensor.sensorName,
                        recentSensorStates: sensingState,
                        recentSensorValues: sensingValues,
                        lastDate: unixTime,
                        info,
                    }),
                );
            }
        },
    });

    return (
        <DashboardWrapper>
            <Legends />
            <MainView />
        </DashboardWrapper>
    );
};

const DashboardWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: min-content auto;
    row-gap: 10px;
`;

export default Dashboard;
