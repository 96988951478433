import { RequestHandlerCreator } from '@api/util';
import { YN } from '@util/type/util';
import { reqGet, reqPost } from '@api/index';

export type PatientAnomalyAlertSettings = {
    alertActive: YN;
    alertOpenCount: number;
    alertRestrictionActive: YN;
    alertRestrictionZones: AlertRestrictionZone[];
};

type AlertRestrictionZone = {
    fcGroupNum: number;
    fcGroupName: string;
    isRestrict: YN;
};

export const getPatientAnomalyAlertSettingsApi: RequestHandlerCreator<PatientAnomalyAlertSettings> = param =>
    reqGet('/v3/api/sh01/realtime/emergency/alert/setting', { ...param });

export const updatePatientAnomalyAlertSettingsApi: RequestHandlerCreator<
    PatientAnomalyAlertSettings,
    PatientAnomalyAlertSettings
> = param => reqPost('/v3/api/sh01/realtime/emergency/alert/setting', param);
